var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "DAV-workshop__trunkFull"
  }, [_c('header', {
    staticClass: "DAV-topBar"
  }, [_c('h2', {
    staticClass: "DAV-topBar__theme-name"
  }, [_vm._v(_vm._s(_vm._f("date")(_vm.themeName)) + " 저장 테마")]), _c('div', {
    staticClass: "DAV-topBar__device"
  }, [_c('button', {
    staticClass: "DAV-topBar__device-pc",
    class: {
      'DAV-topBar__device--active': _vm.deviceType === 'desktop'
    },
    on: {
      "click": function click($event) {
        return _vm.setDeviceType('desktop');
      }
    }
  }, [_vm._v(" pc ")]), _c('button', {
    staticClass: "DAV-topBar__device-mobile",
    class: {
      'DAV-topBar__device--active': _vm.deviceType === 'mobile'
    },
    on: {
      "click": function click($event) {
        return _vm.setDeviceType('mobile');
      }
    }
  }, [_vm._v(" mobile ")])]), _c('button', {
    staticClass: "DAV-topBar__theme-revert",
    on: {
      "click": function click($event) {
        return _vm.themeRevertConfirm();
      }
    }
  }, [_vm._v(" 테마 되돌리기 ")]), _c('button', {
    staticClass: "DAV-topBar__prev",
    on: {
      "click": function click($event) {
        return _vm.setPopup({
          page: null
        });
      }
    }
  }, [_vm._v(" 테마 미리보기 닫기 ")])]), _c('div', {
    staticClass: "DAV-editPage"
  }, [_c('ul', {
    staticClass: "DAV-editPage__list"
  }, _vm._l(_vm.editablePages, function (page, index) {
    return _c('li', {
      key: index,
      staticClass: "DAV-editPage__item"
    }, [_c('button', {
      class: {
        'DAV-editPage__item--checked': index === _vm.editableIndex
      },
      on: {
        "click": function click($event) {
          return _vm.setEditPage(index);
        }
      }
    }, [_vm._v(" " + _vm._s(page.name) + " ")])]);
  }), 0)]), _c('div', {
    staticClass: "DAV-preview"
  }, [_c('div', {
    staticClass: "DAV-preview__shop",
    class: _vm.setPreviewClass
  }, [_c('iframe', {
    staticStyle: {
      "width": "100%",
      "height": "100%",
      "border": "0"
    },
    attrs: {
      "id": "history-preview",
      "src": _vm.url,
      "scrolling": "no"
    }
  })])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }