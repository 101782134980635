<template>
  <div class="DAV-workshop__trunkFull">
    <header class="DAV-topBar">
      <h2 class="DAV-topBar__theme-name">{{ themeName | date }} 저장 테마</h2>
      <div class="DAV-topBar__device">
        <button
          class="DAV-topBar__device-pc"
          :class="{'DAV-topBar__device--active': deviceType === 'desktop'}"
          @click="setDeviceType('desktop')"
        >
          pc
        </button>
        <button
          class="DAV-topBar__device-mobile"
          :class="{'DAV-topBar__device--active': deviceType === 'mobile'}"
          @click="setDeviceType('mobile')"
        >
          mobile
        </button>
      </div>
      <button class="DAV-topBar__theme-revert" @click="themeRevertConfirm()">
        테마 되돌리기
      </button>
      <button class="DAV-topBar__prev" @click="setPopup({page: null})">
        테마 미리보기 닫기
      </button>
    </header>
    <div class="DAV-editPage">
      <ul class="DAV-editPage__list">
        <li
          class="DAV-editPage__item"
          v-for="(page, index) in editablePages"
          :key="index"
        >
          <button
            :class="{'DAV-editPage__item--checked': index === editableIndex}"
            @click="setEditPage(index)"
          >
            {{ page.name }}
          </button>
        </li>
      </ul>
    </div>
    <div class="DAV-preview">
      <div class="DAV-preview__shop" :class="setPreviewClass">
        <iframe
          id="history-preview"
          :src="url"
          style="width: 100%; height: 100%; border: 0"
          scrolling="no"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {createNamespacedHelpers} from "vuex";

const ModuleTheme = createNamespacedHelpers("ModuleTheme");
const ModuleEditor = createNamespacedHelpers("ModuleEditor");
const ModuleEditorContainer = createNamespacedHelpers("ModuleEditorContainer");
const ModuleConfirm = createNamespacedHelpers("ModuleConfirm");

export default {
  data() {
    return {
      editableIndex: 0,
      editablePages: {},
      themeName: null,
      deviceType: null,
      solutionName: null
    };
  },
  computed: {
    // class binding - 디바이스 타입
    setPreviewClass() {
      return {
        "DAV-preview__shop--mobile": this.deviceType === "mobile",
        "DAV-preview__shop--desktop": this.deviceType === "desktop"
      };
    },
    url() {
      return this.getMode === "partner"
        ? `/preview/${this.getThemeId}?mode=partner`
        : `/preview/${this.getThemeId}`;
    },
    ...ModuleTheme.mapGetters([
      "getSaveHistoryListData",
      "getSavePartnerHistoryListData"
    ]),
    ...ModuleEditor.mapGetters([
      "getPopup",
      "getThemeId",
      "getMode",
      "getSolutionListData"
    ])
  },
  mounted() {
    // 디바이스 선택
    this.deviceType =
      document.documentElement.clientWidth < 1024 ? "mobile" : "desktop";

    // 컴포넌트 불러오기
    this.init();
  },
  methods: {
    // 컴포넌트 Init
    init() {
      const list =
        this.$route.query.mode === "partner"
          ? this.getSavePartnerHistoryListData.list
          : this.getSaveHistoryListData.list;
      const themeIndex = list.findIndex(e => e.id === this.getPopup.index);
      const theme = list[themeIndex];
      this.themeName = theme.createdAt;
      this.editablePages = theme.data.editablePages;

      // 솔루션 네임
      for (let solution of this.getSolutionListData) {
        if (theme.data.theme.solutionId === solution.id) {
          this.solutionName = solution.name;
        }
      }

      // data send to iframe
      setTimeout(() => {
        document.getElementById("history-preview").contentWindow.postMessage(
          {
            action: "init",
            id: "history-preview",
            theme: theme.data,
            device: this.deviceType
          },
          `${window.location.origin}/preview`
        );
      }, 1000);
    },
    // 페이지 선택
    setEditPage(index) {
      this.editableIndex = index;
      document
        .getElementById("history-preview")
        .contentWindow.postMessage(
          {action: "page", page: index},
          `${window.location.origin}/preview`
        );
    },
    // 디바이스 선택
    setDeviceType(deviceType) {
      this.deviceType = deviceType;
      document
        .getElementById("history-preview")
        .contentWindow.postMessage(
          {action: "device", device: this.deviceType},
          `${window.location.origin}/preview`
        );
    },
    // 테마 되돌리기 확인
    themeRevertConfirm() {
      this.setConfirm({
        isVisible: true,
        message: `이 저장 상태로 편집 테마를 사용하시겠습니까?`,
        messageBtn: `사용하기`,
        event: () => this.themeRevert()
      });
    },
    // 테마 되돌리기
    themeRevert() {
      this.$store.dispatch("ModuleUser/actUserLog", {
        eventName: "백업 테마 적용",
        requestLocation: "큐픽 > 어드민 > 테마관리 > 편집 > 저장 히스토리",
        solutionName: this.solutionName,
        themeId: this.getThemeId,
        createdAt: this.themeName
      });
      this.setThemeLoad(this.getPopup.value);
      this.setUserThemeHistoryId(this.getPopup.index);
      this.setPopup({page: null});
      this.setEvent("reset");
      this.setAttach(true);
      this.setPanel("ComponentList");
    },

    ...ModuleEditor.mapMutations([
      "setPopup",
      "setAttach",
      "setPanel",
      "setUserThemeHistoryId"
    ]),
    ...ModuleTheme.mapMutations(["setThemeLoad"]),
    ...ModuleEditorContainer.mapMutations(["setEvent"]),
    ...ModuleConfirm.mapMutations(["setConfirm"])
  }
};
</script>

<style lang="scss" scoped>
.DAV-workshop__trunkFull {
  z-index: 12;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #e7e9ec;
}
.DAV-topBar {
  border-bottom: none;

  &__theme-name {
    padding-left: 16px;
  }
  &__prev {
    position: absolute;
    top: 16px;
    right: 25px;
    width: 25px;
    height: 25px;
    background-image: url($path + "btn_close_popup.svg");
    background-repeat: no-repeat;
    background-position: center;
    text-indent: -9999px;
    background-size: cover;
  }
  &__theme-revert {
    position: absolute;
    top: 16px;
    right: 72px;
    width: 24px;
    height: 24px;
    background-image: url($path + "ico_save_revert.svg");
    background-repeat: no-repeat;
    background-position: 2px 2px;
    text-indent: -9999px;
  }
}
.DAV-editPage {
  z-index: 1;
  position: absolute;
  top: 56px;
  right: 0;
  left: 0;
  background-color: #f7f9fc;

  ::-webkit-scrollbar {
    height: 4px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #c4c4c4;
    border-radius: 4px;
  }
  ::-webkit-scrollbar-track {
    display: none;
  }
  &__list {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    overflow-x: auto;
    height: 72px;
    padding: 0 8px 0 16px;
  }
  &__item {
    flex: 0 0 auto;
    margin-right: 8px;

    button {
      height: 40px;
      padding: 0 16px;
      background: #ffffff;
      border: 1px solid #dadada;
      border-radius: 8px;
      box-sizing: border-box;
    }
    &--checked {
      color: #ffffff;
      background-color: #54c7a2 !important;
      border: none !important;
    }
  }
}
.DAV-preview {
  position: fixed;
  top: 56px;
  right: 421px;
  bottom: 0;
  left: 0;
  background-color: #e7e9ec;

  &__shop {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    background-color: #ffffff;
  }
  &__shop--desktop {
    margin-right: 16px;
  }
}
/* 반응형 - desktop */
@media screen and (min-width: 1024px) {
  .DAV-topBar__theme-revert {
    top: 8px;
    width: 40px;
    height: 40px;
    border: 1px solid #cdcdcd;
    box-sizing: border-box;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    background-position: 10px 10px;
  }
  .DAV-editPage {
    bottom: 0;
    left: auto;
    width: 421px;

    &__list {
      flex-direction: column;
      height: auto;
      padding: 8px 16px 16px;
    }
    &__item {
      width: 100%;
      margin: 8px 0 0;

      button {
        width: 100%;
        height: 56px;
        text-align: left;
        background-image: url($path + "bul_selected.svg");
        background-repeat: no-repeat;
        background-position: right 16px center;
        background-size: 18px auto;
      }
    }
  }
  .DAV-preview__shop--mobile {
    width: 421px;
    left: 50%;
    transform: translateX(-50%);
  }
}
/* 반응형 - mobile */
@media screen and (max-width: 1023px) {
  .DAV-topBar__device {
    right: 104px;
  }
  .DAV-topBar__theme-revert {
    right: 61px;
  }
  .DAV-preview {
    top: 56px + 72px;
    right: 0;
    border-top: 1px solid #f1f1f1;
  }
  .DAV-preview__shop {
    left: 50%;
    transform: translateX(-50%);
    width: 100%;

    &--desktop {
      margin-right: 0;
    }
  }
}
</style>
